.buttonn{
    color:#000;
   display:flex;
   justify-content:center;
   align-items:center;
   border-radius:5px;
   padding:5px;
   margin-right:2px;
   border:none;
}
.buttonn:hover{
    cursor:pointer;
}
.connect{
    padding:'1rem';
    font-sizing:'1rem'
}