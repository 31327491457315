:root{
  --main-max-width:1440px
}
body{
  /* font-size:30px !important; */
}
.AppbuttonTel{
  border-radius: 20px;
  height: 100%;
  background-color: #005EB8;
  border: 1px white solid    ;
  color: #fff;
  font-weight: 700;
  padding: 3px 10px;
  transition: 250ms;
  margin-left: 5px;
  font-size: 12px ;
  align-items: center;
  justify-content: center;
  display: flex;
  /* color: #005fb890; */
  
  
}

/* a{
  text-decoration: none;
} */

