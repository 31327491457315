.Header{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  
    /* background-color: transparent; */
    z-index: 10;
    height: 70px;
    /* opacity: 0.7; */
}

.toolBar{

    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 1440px;
    margin: auto;
   

}

  .divLink{
cursor: pointer;
  }
  .divLink:hover{
color: #4EAFFF;
  }

#map{
    width: 320px;
}
.menuIcon{
    display: none;
}

.navLinks{
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: 900;
    font-size: 19px;
    height: 100%;
}
.ant-dropdown-link {
    display: flex;
    align-items: center;
    color: #004180;

    

}
.ant-dropdown-link:hover{
  
    /* font-weight: 900; */
    /* font-style:oblique; */
}

@media (max-width: 640px) {
    .menuIcon{
        display: block;
    }
    .toolBar{

        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
       
    
    }

  }
@media (max-width: 640px) {
    .navLinks{
        display: none;
    }

  }